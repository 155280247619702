import React from "react";
import { Slide } from "react-awesome-reveal";
import BImage from "gatsby-background-image";
import useImage from "../../hooks/use-image";

export default function ClientsSection() {
  const { heliSM, heliMD, heliLG, heliXL } = useImage();
  const heliSrc = [
    heliSM.sharp.fluid,
    { ...heliMD.sharp.fluid, media: `(min-width: 500px)` },
    { ...heliLG.sharp.fluid, media: `(min-width: 1024px)` },
    { ...heliXL.sharp.fluid, media: `(min-width: 1200px)` },
  ];
  return (
    <BImage Tag="section" fluid={heliSrc} className="h-full z-10">
      <div>
        <div className="py-12 md:py-16 mx-auto container p-4 lg:py-24 2xl:py-64 lg:max-w-6xl">
          <Slide triggerOnce direction="left">
            <h2 className="text-left text-3xl text-white lg:text-4xl font-semibold xl:text-5xl lg:mx-auto">
              Supporting our Customers
            </h2>
            <p className="text-base text-white mt-4 lg:mt-8 lg:text-xl">
              Clarity focuses on maintaining long-term partnerships with our
              customers and dedicating our resources to advancing their
              organizational mission and goals. Our rapid prototyping, Agile and
              DevSecOps processes, automation, machine learning, and smart
              systems offer a clear advantage to customers excited about
              modernization, streamlining, and staying ahead of our adversaries.
              By working alongside end users, we develop lasting solutions that
              deliver results and contribute to mission outcomes. We help the
              organizations we serve stay ahead of the evolving adversarial and
              technology landscapes by investing in and deploying next-gen and
              emerging solutions.
            </p>
            <p className="text-base text-white mt-4 xl:mt-8 lg:text-xl">
              For clients looking to win tomorrow’s wars, Clarity links
              customers to the people, processes, and technologies that
              positively impact missions. We offer speed and demonstrate the
              possible.
            </p>
          </Slide>
        </div>
      </div>
    </BImage>
  );
}
